import Vue from 'vue'
import VueProgressBar from 'vue-progressbar'

const options = {
    color: '#45cc91',
    failedColor: '#ff6a6a',
    thickness: '8px',
    transition: {
        speed: '0.2s',
        opacity: '0.6s',
        termination: 300
    }
}

Vue.use(VueProgressBar, options)

export default new Vue().$mount('#progress')
